import React, { useState, useEffect, useRef } from 'react';

import {
  BoxSelection,
  BoxSelectionBoxSize as BoxSize,
  Text,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
  TextButton,
  Dropdown,
} from 'wix-ui-tpa/cssVars';

import {
  TimeSlotsSelectionViewModel,
  TimeSlot,
} from '../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import { classes, st } from './TimeSlotsSelection.st.css';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DataHooks, TimeSlotsSelectionDisplayMode } from './constants';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { Optional, TimeSlotComponent } from '../../../../types/types';
import { useTimeSlotSelectionProps } from './useTimeSlotSelectionProps';
import { useCreateCssVarsClassContainer } from '../../Hooks/useCreateCssVarsClassContainer';

export type TimeSlotsSelectionProps = {
  viewModel: TimeSlotsSelectionViewModel;
  displayMode: TimeSlotsSelectionDisplayMode;
  date: string;
};

export type TimeSlotsSelectionComponentProps = {
  viewModel: TimeSlotsSelectionViewModel;
  date: string;
};

export const TIME_DELAY_BEFORE_FOCUS = 0;

const TimeSlotsSelectionDeprecated: React.FC<TimeSlotsSelectionProps> = ({
  viewModel,
  displayMode,
  date,
}) => {
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    showAllButtonText,
    highlightedSlotDetails,
  } = viewModel;
  const boxSelectionRef = React.useRef(null as any);
  const { onTimeSelected, onElementClicked } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] =
    useState(0);
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;
  const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
    ? maxNumberOfTimeSlotsToDisplay
    : timeSlots.length;

  useEffect(() => {
    setNumberOfTimeSlotsToDisplay(defaultNumberOfSlotsToDisplay);
  }, [
    defaultNumberOfSlotsToDisplay,
    maxNumberOfTimeSlotsToDisplay,
    shouldLimitNumberOfTimeSlotsDisplayed,
  ]);

  useEffect(() => {
    if (highlightedSlotDetails.shouldFocusOnRender) {
      setTimeout(
        () =>
          boxSelectionRef.current?.focus?.(highlightedSlotDetails.slotIndex),
        TIME_DELAY_BEFORE_FOCUS,
      );
    }
  }, [highlightedSlotDetails]);

  const onShowAllClick = () => {
    setNumberOfTimeSlotsToDisplay(timeSlots.length);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };

  useEffect(() => {
    if (!isMobile) {
      boxSelectionRef.current?.focus?.(defaultNumberOfSlotsToDisplay);
    }
  }, [defaultNumberOfSlotsToDisplay, numberOfTimeSlotsToDisplay, isMobile]);

  return (
    <div className={st(classes.root, { isMobile, displayMode })}>
      <BoxSelection
        key={`${DataHooks.TimeSlotsSelection}-${date}`}
        name={`${DataHooks.TimeSlotsSelection}-${date}`}
        data-hook={`${DataHooks.TimeSlotsSelection}-${date}`}
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={({ id }) => onTimeSelected(id)}
        ref={boxSelectionRef}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const {
              selected,
              allSlotsAreFull,
              tooLateToBookAllSlots,
              tooEarlyToBookAllSlots,
              withWaitingList,
              allSlotsAreLocked,
            } = timeSlot.status;
            const isTimeSlotFull =
              allSlotsAreFull && !withWaitingList && !allSlotsAreLocked;
            const isDisabled =
              isTimeSlotFull || tooLateToBookAllSlots || tooEarlyToBookAllSlots;
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={isDisabled}
                unavailable={isTimeSlotFull || tooLateToBookAllSlots}
              >
                <Text
                  data-hook={`${DataHooks.TimeSlotText}-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  <div aria-hidden={true} className={classes.startTime}>
                    {timeSlot.formattedStartTime}
                  </div>
                  {withWaitingList || allSlotsAreLocked ? (
                    <div aria-hidden={true} className={classes.waitingList}>
                      {viewModel.waitlistText}
                    </div>
                  ) : null}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          key={`${DataHooks.ShowAllButton}-${date}`}
          data-hook={`${DataHooks.ShowAllButton}-${date}`}
          priority={TEXT_BUTTON_PRIORITY.link}
          onClick={onShowAllClick}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </div>
  );
};

const TimeSlotsSelection: React.FC<TimeSlotsSelectionProps> = ({
  viewModel,
  displayMode,
  date,
}) => {
  const { timeSlotComponent } = viewModel;
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const displayTimeSlotInDropdown =
    timeSlotComponent === TimeSlotComponent.DROPDOWN;

  return experiments.enabled('specs.bookings.timeslotComponentSelection') ? (
    <div className={st(classes.root, { isMobile, displayMode })}>
      {displayTimeSlotInDropdown ? (
        <DropdownTimeSlotsSelection viewModel={viewModel} date={date} />
      ) : (
        <ButtonsTimeSlotsSelection viewModel={viewModel} date={date} />
      )}
    </div>
  ) : (
    <TimeSlotsSelectionDeprecated
      viewModel={viewModel}
      displayMode={displayMode}
      date={date}
    />
  );
};

export default TimeSlotsSelection;

const ButtonsTimeSlotsSelection: React.FC<TimeSlotsSelectionComponentProps> = ({
  viewModel,
  date,
}) => {
  const boxSelectionRef = React.useRef(null as any);

  const { onElementClicked } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    highlightedSlotDetails,
    showAllButtonText,
    waitlistText,
  } = viewModel;
  const { isTimeSlotDisabled, isTimeSlotUnavailable, onTimeSlotChanged } =
    useTimeSlotSelectionProps();

  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] =
    useState(0);
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;
  const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
    ? maxNumberOfTimeSlotsToDisplay
    : timeSlots.length;

  useEffect(() => {
    setNumberOfTimeSlotsToDisplay(defaultNumberOfSlotsToDisplay);
  }, [
    defaultNumberOfSlotsToDisplay,
    maxNumberOfTimeSlotsToDisplay,
    shouldLimitNumberOfTimeSlotsDisplayed,
  ]);

  useEffect(() => {
    if (highlightedSlotDetails.shouldFocusOnRender) {
      setTimeout(
        () =>
          boxSelectionRef.current?.focus?.(highlightedSlotDetails.slotIndex),
        TIME_DELAY_BEFORE_FOCUS,
      );
    }
  }, [highlightedSlotDetails]);

  useEffect(() => {
    if (!isMobile) {
      boxSelectionRef.current?.focus?.(defaultNumberOfSlotsToDisplay);
    }
  }, [defaultNumberOfSlotsToDisplay, numberOfTimeSlotsToDisplay, isMobile]);

  const onShowAllClick = () => {
    setNumberOfTimeSlotsToDisplay(timeSlots.length);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };
  return (
    <>
      <BoxSelection
        key={`${DataHooks.TimeSlotsSelection}-${date}`}
        name={`${DataHooks.TimeSlotsSelection}-${date}`}
        data-hook={`${DataHooks.TimeSlotsSelection}-${date}`}
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={onTimeSlotChanged}
        ref={boxSelectionRef}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const { selected, withWaitingList, allSlotsAreLocked } =
              timeSlot.status;
            const isDisabled = isTimeSlotDisabled(timeSlot);
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={isDisabled}
                unavailable={isTimeSlotUnavailable(timeSlot)}
              >
                <Text
                  data-hook={`${DataHooks.TimeSlotText}-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  <div aria-hidden={true} className={classes.startTime}>
                    {timeSlot.formattedStartTime}
                  </div>
                  {withWaitingList || allSlotsAreLocked ? (
                    <div aria-hidden={true} className={classes.waitingList}>
                      {waitlistText}
                    </div>
                  ) : null}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          key={`${DataHooks.ShowAllButton}-${date}`}
          data-hook={`${DataHooks.ShowAllButton}-${date}`}
          priority={TEXT_BUTTON_PRIORITY.link}
          onClick={onShowAllClick}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </>
  );
};

const DropdownTimeSlotsSelection: React.FC<
  TimeSlotsSelectionComponentProps
> = ({ viewModel, date }) => {
  const { t } = useTranslation();

  const { timeSlots, waitlistText, appendToViewPort } = viewModel;
  const {
    isTimeSlotDisabled,
    isTimeSlotUnavailable,
    onTimeSlotChanged,
    isAllSlotsNotBookable,
  } = useTimeSlotSelectionProps();
  const { cssVarsContainerClassName, rootRef } =
    useCreateCssVarsClassContainer();

  const placeholder = isAllSlotsNotBookable(viewModel.timeSlots)
    ? t('slot-selection.dropdown.placeholder.no-bookable-slots')
    : t('slot-selection.dropdown.placeholder.select-time');

  return (
    <div ref={rootRef}>
      <Dropdown
        upgrade
        key={`${DataHooks.TimeSlotsSelection}-${date}`}
        name={`${DataHooks.TimeSlotsSelection}-${date}`}
        data-hook={`${DataHooks.TimeSlotsSelection}-${date}`}
        className={classes.dropdownSelection}
        appendTo={appendToViewPort ? 'viewport' : undefined}
        optionsContainerClass={cssVarsContainerClassName}
        onChange={onTimeSlotChanged}
        options={timeSlots.map((timeSlot: TimeSlot) => {
          return {
            id: timeSlot.rfcStartTime,
            value:
              timeSlot.status.withWaitingList ||
              timeSlot.status.allSlotsAreLocked
                ? t('slot-selection.dropdown.slot-with-waitlist', {
                    slotTime: timeSlot.formattedStartTime,
                    waitListText: waitlistText,
                  })
                : timeSlot.formattedStartTime,
            unavailable: isTimeSlotUnavailable(timeSlot),
            isSelectable: !isTimeSlotDisabled(timeSlot),
          };
        })}
        initialSelectedId={
          timeSlots.find((timeslot) => timeslot.status.selected)?.rfcStartTime
        }
        placeholder={placeholder}
      ></Dropdown>
    </div>
  );
};
